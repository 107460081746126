import { VStack } from "@chakra-ui/react";
import React from "react";

import { EventDomainType } from "../types";
import { NextLink } from "./common";
import { colors } from "../styles";
import { GOI_WEB_URL } from "../constants";

interface SearchHeaderProps {
  eventPath: keyof typeof EventDomainType;
}

export const SearchHeader = ({ eventPath }: SearchHeaderProps) => {
  if (eventPath === "metro") {
    return (
      <VStack
        maxWidth="100%"
        padding="25px 15px 25px 15px"
        style={{
          background: `linear-gradient(to bottom, ${colors.warmGray200}, #ffffff)`,
        }}
      >
        <VStack
          width="100%"
          as="ul"
          alignContent="start"
          padding="20px"
          background={colors.white}
          borderRadius="10px"
          boxShadow="0px 2px 6px rgba(0, 0, 0, 0.16)"
        >
          <h3
            style={{
              width: "100%",
              paddingBottom: "15px",
            }}
          >
            자주 묻는 질문 (FAQ)
          </h3>
          <li
            className="gray800"
            style={{
              width: "100%",
              padding: "10px 0 10px 0",
              borderBottom: `1px solid ${colors.gray50}`,
            }}
          >
            <NextLink
              href={`${GOI_WEB_URL}/guidebook/tip/before/4?utm_source=dogsound&utm_campaign=top_FAQ`}
              event={{
                domain: eventPath,
                path: "header",
                trigger_and_target: "content-uv_before_4",
              }}
            >
              장례식장 vs 상조회사 나에게 맞는 곳은? &gt;
            </NextLink>
          </li>
          <li
            className="gray800"
            style={{
              width: "100%",
              padding: "10px 0 10px 0",
            }}
          >
            <NextLink
              href={`${GOI_WEB_URL}/funeral-prepare/1?utm_source=dogsound&utm_campaign=top_FAQ`}
              event={{
                domain: eventPath,
                path: "header",
                trigger_and_target: "content-uv_funeral_1",
              }}
            >
              처음하는 장례준비 어떻게 해야 후회 없을까요? &gt;
            </NextLink>
          </li>
        </VStack>
      </VStack>
    );
  }

  return null;
};
