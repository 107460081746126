import { Box, Stack } from "@mui/material";
import React from "react";

import { ClickEventType, ParlorDetailProps } from "../../../types";

import ATypeRegionPriceSummaryItem from "./ATypeRegionPriceSummaryItem";
import { BTypeMainHead } from "../MainHead";
import { ColorSystemProps } from "../../../constants";
import { FullWidthRadiusButton } from "../../common/Buttons/FullWidthRadiusButton";
import { CTypeCTA } from "../CTA";

export function BTypeRegionHead({
  regionParlors,
  sidoName,
  sigunguName,
  expectedParlorPrice,
  mainColor,
  event,
}: {
  regionParlors: Array<ParlorDetailProps>;
  sidoName: string;
  sigunguName?: string;
  expectedParlorPrice: any;
  mainColor: ColorSystemProps;
  event: ClickEventType;
}) {
  const name = `${sidoName}${sigunguName ? " " + sigunguName : ""}`;

  return (
    <>
      <BTypeMainHead
        title={`${sidoName}${
          sigunguName ? ` ${sigunguName} ` : " "
        }장례식장 추천`}
        sidoName={sidoName}
        sigunguName={sigunguName}
        defaultParlor={regionParlors[0]}
        isRegion
        event={event}
      />

      <Stack
        gap="6px"
        padding="40px 20px 20px"
        margin="20px 12px"
        style={{
          background: "white",
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <CTypeCTA mainColor={mainColor} event={event} />
      </Stack>
      <Stack
        gap="6px"
        padding="40px 20px 20px"
        margin="20px 12px"
        style={{
          background: "white",
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box className="subtitle_18_b">{name} 평균 빈소 비용</Box>

        <PriceSummaryItem
          text="조문객 50명 기준 평균 빈소 비용"
          price={expectedParlorPrice.무빈소장}
        />
        <PriceSummaryItem
          text="조문객 100명 기준 평균 빈소 비용"
          price={expectedParlorPrice.가족장}
        />
        <PriceSummaryItem
          text="조문객 200명 기준 평균 빈소 비용"
          price={expectedParlorPrice.일반장}
        />
      </Stack>
    </>
  );
}

function PriceSummaryItem({ text, price }: { text: string; price: number }) {
  return (
    <Stack
      className="subtitle_14_b"
      flexDirection="row"
      color="black"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        component="h4"
        flexShrink={0}
        className="gray800"
        padding="0 10px 0 0"
      >
        {text}
      </Box>

      <p className="subtitle_14_b">{price}원</p>
    </Stack>
  );
}
