export const SHORT = {
  process: "proc",
  recommend: "recom",
  compare: "comp",
  comparison: "compsn",
  first: "fst",
  second: "scd",
} as const;

export const SHORT_FUNERAL = {
  parlor: "prlr",
  crematorium: "crema",
  funeral: "fune",
  facility: "fac",
  facilities: "facs",
} as const;

type Short = typeof SHORT;
type ShortFuneral = typeof SHORT_FUNERAL;

export type CommonEventProps = ActionEvent | ClickEventType;

export interface ClickEventType {
  action?: "click";
  domain: keyof typeof EventDomainType;
  path: EventPathType;
  trigger_and_target?: EventTriggerAndTargetType;
  text?: string;
}

interface ActionEvent {
  action:
    | "call"
    | "goi_call"
    | "move_to_goi"
    | "gyeongsang_open_parlor50_modal";
}

export enum EventDomainType {
  "ch" = "ch",
  "dggb" = "dggb",
  "gangwon" = "gangwon",
  "gyeongsang" = "gyeongsang",
  "honam" = "honam",
  "metro" = "metro",
  "common" = "common",
  "heysangjo" = "heysangjo",
}

export type EventPathType =
  | "header"
  | "footer"
  | "home"
  | "benef"
  | "support"
  | "all_prcss_recm"
  | "nat"
  | "nat_hr"
  | "nat_hr_fac"
  | "nat_hr_crema"
  | "nat_merit"
  | "nat_merit_crema"
  | "nat_merit_facility"
  | "nat_hr_fr_crema"
  | "nat_hr_fr_facility"
  | "nat_fr-crema"
  | "nat_fr_facility"
  | "fr_crema"
  | "fr_benef"
  | "prlrs"
  | "prlr"
  | "prlr_recm"
  | "prlr_binso"
  | "prlr_flower"
  | "prlr_price"
  | "prlr_intro"
  | "prlr_service"
  | "prlr_list"
  | "prlr_list_sigun"
  | "prlr_item"
  | "prlr_item_intro"
  | "prlr_item_binso"
  | "prlr_item_flower"
  | "prlr_item_price"
  | "prlr_item_service"
  | "prlr_compare"
  | "facil_item_intro"
  | "facil_item_price"
  | "facil_item_reservation"
  | "price"
  | "benef_crema"
  | "benef_sup"
  | "benef_sup_fr_crema"
  | "benef_sup_fr_facility"
  | "benef_pub_facility"
  | "benef_facility"
  | "facility"
  | "facility_price"
  | "benef_sup_fr_fac"
  | "chk_compare"
  | "all_prcss_recm"
  | "all-funeral"
  | "veterans"
  | "veterans_facility"
  | "veterans_crema"
  | "compare_prlr"
  | "fr-crema"
  | "fr_facility"
  | "funeral_recm"
  | "fr_nat"
  | "funeral_binso"
  | "funeral_price"
  | "funeral_place"
  | "funeral_service"
  | "funeral_intro"
  | "funeral_flower"
  | "funeral_place_binso"
  | "funeral_place_flower"
  | "funeral_place_price"
  | "funeral_place_service"
  | "funeral_place_estimate"
  | `${ShortFuneral["funeral"]}_${Short["process"]}_${Short["recommend"]}`
  | `${ShortFuneral["funeral"]}_${Short["process"]}_${Short["recommend"]}_list`
  | `${ShortFuneral["funeral"]}_${Short["recommend"]}`
  | `${ShortFuneral["funeral"]}_${Short["recommend"]}_list`
  | `${Short["comparison"]}`
  | `${Short["comparison"]}_list`
  | "refund_calc_sangjo"
  | "refund_calc_sangjo_detail"
  | "closed"
  | "";

export type EventTriggerAndTargetType =
  | "file_request_mdl-submit_success"
  | "file_request_mdl-try_submit"
  | "footer1_checklist_modal"
  | "footer2_discount_chat"
  | "footer3_call"
  | "CTA_uv_mth_req"
  | "CTA_uv_facil_req"
  | "CTA_uv_wreath_req"
  | "CTA_uv_funeral_cost"
  | "A_facil_FAQ_uv_mth_facil_req"
  | "AType_tab"
  | "AType_CTA_uv_prlr_req"
  | "AType_service"
  | "AType_binso_summary"
  | "BType_tab"
  | "CType_tab"
  | "CType_tab-introduce"
  | "CType_tab-price"
  | "CType_tab-binso"
  | "CType_tab-flower"
  | "CType_tab-service"
  | "CType_facil_tab"
  | "DType_facil_tab"
  | "DType_tab"
  | "br_crumb_goi_home"
  | "goi_call"
  | "goi_call_btom"
  | `${ShortFuneral["parlor"]}_goi_call`
  | `${ShortFuneral["crematorium"]}_goi_call`
  | `${ShortFuneral["facility"]}_goi_call`
  | "parlor_goi_call"
  | "facility_goi_call"
  | "crema_goi_call"
  | "emergen-btn_consult-call"
  | "parlor_information"
  | "tab_price"
  | "discount_btn-uv_mtch_rqst_prlr"
  | "uv_wreath_req"
  | "uv_wreath_req-top"
  | "uv_basic_livelihood"
  | "uv_facility"
  | "uv_facility_price"
  | "uv_mth_req"
  | "uv_mth_facil_req"
  | `${ShortFuneral["parlor"]}_uv_mth_req`
  | `${ShortFuneral["crematorium"]}_uv_mth_req`
  | `${ShortFuneral["facility"]}_uv_mth_req`
  | "uv_mth_req"
  | "uv_mth_req_btom"
  | "uv_prlr_detail"
  | `uv_${ShortFuneral["parlor"]}_${Short["compare"]}_${Short["first"]}_${Short["second"]}`
  | "uv_expert_mth_req"
  | "uv_prlr_item"
  | "uv_crema"
  | "uv_crema_detail"
  | "uv_prlr_req"
  | "uv_prlr_detail"
  | "uv_facility_detail"
  | "uv_funeral_detail"
  | "uv_funeral_place"
  | "uv_funeral_place_price"
  | "uv_first_parlor"
  | "uv_second_parlor"
  | "uv_sigun_prlr_detail"
  | "uv_sido_prlr_detail"
  | "uv_service_detail"
  | "uv_nat_facility_detail"
  | "uv_nat_crema_detail"
  | "uv_wreath_req_btom"
  | `uv_${ShortFuneral["funeral"]}_${Short["process"]}_${Short["recommend"]}`
  | `uv_${ShortFuneral["funeral"]}_${Short["process"]}_${Short["recommend"]}_list`
  | `uv_${ShortFuneral["funeral"]}_${Short["recommend"]}`
  | `uv_${ShortFuneral["funeral"]}_${Short["recommend"]}_list`
  | `uv_${Short["comparison"]}`
  | `uv_${Short["comparison"]}_list`
  | "benef_sup"
  | "benef"
  | "benef_fr_crema-uv_crema"
  | "benef_fr_fac-uv_fac"
  | "br_crumb_nat_hr"
  | "br_crumb_benef"
  | "br_crumb_benef_sup"
  | "br_crumb_nat_merit"
  | "br_crumb_crema"
  | "br_crumb_facility"
  | "mth_req"
  | "br_crumb_mth_req"
  | "br_crumb_mth_facil_req"
  | "br_crumb_sido_prlr_list"
  | "br_crumb_sigungu_prlr_list"
  | "br_crumb_benef_facility"
  | "br_crumb_benef_crema"
  | "br_crumb_veterans"
  | "br_crumb_veterans_facility"
  | "br_crumb_prlr_list"
  | "br_crumb_fr_nat"
  | "br_crumb_fr_benef"
  | "uv_binso"
  | "retry"
  | "sido_prlr_list"
  | "sigungu_prlr_list"
  | "nat_hr_prlr_list"
  | "fr_facility_list"
  | "fr_crema_list"
  | "nat_hr"
  | "nat_merit"
  | "uv_fr_crema_detail"
  | "uv_fr_facility_detail"
  | "consult_btn-call_btom"
  | "all_pro_recm-uv_mth_req"
  | "all_process_recm-call"
  | "all_recm-uv_mth_req_btom"
  | "all_recm-call_btom"
  | "send_question_email"
  | "send_question_email_try"
  | "send_binso_reserve_email_try"
  | "send_binso_reserve_email"
  | "opn_reserve_mdl"
  | "calculate_refund_fee"
  | "uv_guide_cancel_contract"
  | "mdl_CTA"
  | "uv_clsd_detail"
  | "uv_clsd_guide"
  | "mdal_parlor50_CTA"
  | "content-uv_funeral_1"
  | "content-uv_before_4";
