import React from "react";

import { colors } from "../styles";
import { Box } from "@chakra-ui/react";

export const ExcludeParlorSection = () => {
  return (
    <Box
      padding="32px"
      background={colors.gray100}
      className="subtitle_18_m gray600"
      borderRadius="16px"
    >
      <span className="gray900">
        해당 장례식장의 요청에 의해
        <br />
        가격, 비용정보, 후기, 사진 등의 정보를 제공하지 않습니다.
        <br />
        <br />
      </span>
      지역 장례식장 비용 비교, 지역내 장례식장 정보가 궁금하신 경우
      e하늘장사정보시스템(https://15774129.go.kr/intro.do)
      <br />
      혹은 <b className="orange700">고이 24시간 고객센터{"(1666-9784)"}</b>로
      문의해주세요
    </Box>
  );
};
