import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import {
  ColorSystemProps,
  excludeParlorList,
  FUNERAL_SIDO,
  FUNERAL_SIGUNGU,
} from "../../../constants";
import { B_2TypeCTA } from "./CTA";
import { BTypePriceSummary } from "./PriceSummary";
import { CTypeFacility } from "./Facility";

import { Image } from "../../common/Image";
import { colors } from "../../../styles";
import { ATypeLocation } from "./Location";
import { ExcludeParlorSection } from "../../ExcludeParlorSection";

export function CTypeCompareParlor({
  firstParlor,
  secondParlor,
  mainColor,
  event,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const firstParlorType = firstParlor.managed_type;
  const secondParlorType = secondParlor.managed_type;

  if (
    excludeParlorList.includes(firstParlor.id.toString()) ||
    excludeParlorList.includes(secondParlor.id.toString())
  ) {
    return <ExcludeParlorSection />;
  }

  return (
    <>
      <Stack
        padding="20px"
        height="220px"
        position="relative"
        style={{ background: "white" }}
        borderBottom={`1px solid ${colors.gray300}`}
        gap="10px"
      >
        <Box className="body_14_m" component="h3">
          {FUNERAL_SIDO.get(Number(firstParlor.sido))}{" "}
          {FUNERAL_SIGUNGU.get(Number(firstParlor.sido))?.get(
            Number(firstParlor.sigungu)
          )}{" "}
          내 장례식장 비교
        </Box>
        <Stack>
          <h2 className="subtitle_21_sb">{firstParlor.companyname}</h2>
          <p className="body_14_m">{firstParlor.address}</p>
        </Stack>
        <Stack>
          <h2 className="subtitle_21_sb">{secondParlor.companyname}</h2>
          <p className="body_14_m">{secondParlor.address}</p>
        </Stack>
        <Stack alignItems="flex-end" className="caption_12_m gray500">
          2023.09.21 13:00 에 작성됨
        </Stack>
      </Stack>

      <Stack
        padding="30px"
        className="subtitle_16_sb gray900"
        style={{ background: "white" }}
        margin="20px 12px 0"
        boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.12)"
        gap="12px"
      >
        <Box>
          장례식장 선택에 다양한 옵션을 갖는 것은 필요한 일입니다. 이번에는{" "}
          <b style={{ color: mainColor.c400 }}>
            {firstParlor.companyname}과 {secondParlor.companyname}
          </b>
          에 대해 상세히 살펴보도록 하겠습니다.
        </Box>
        <Box>
          각각 {FUNERAL_SIDO.get(Number(firstParlor.sido))}에 위치한
          장례식장으로,
          {firstParlor.companyname}은 {firstParlor.address},{" "}
          {secondParlor.companyname}은 {secondParlor.address}에 위치해있습니다.
        </Box>
        <Box>
          장례식장 유형은
          <b style={{ color: mainColor.c400 }}>
            {firstParlor.companyname} : {firstParlorType} 장례식장,{" "}
            {secondParlor.companyname} : {secondParlorType} 장례식장
          </b>
          으로 나뉘며, 더 자세한 정보를 아래에서 설명해보겠습니다.
        </Box>
      </Stack>

      <Stack
        padding="20px"
        className="body_16_m gray900"
        style={{ background: "white" }}
        margin="20px 12px 0"
        boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.12)"
      >
        <CTypeFacility
          firstParlor={firstParlor}
          secondParlor={secondParlor}
          mainColor={mainColor}
          event={event}
        />
      </Stack>

      <Stack
        padding="20px 20px 0"
        className="body_16_m gray900"
        style={{ background: "white" }}
        margin="20px 12px 0"
        boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.12)"
      >
        <BTypePriceSummary
          firstParlor={firstParlor}
          secondParlor={secondParlor}
          mainColor={mainColor}
        />
      </Stack>

      <Stack
        padding="20px"
        className="body_16_m gray900"
        style={{ background: "white" }}
        margin="20px 12px 0"
        boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.12)"
      >
        <ATypeLocation firstParlor={firstParlor} secondParlor={secondParlor} />
      </Stack>
      <Stack padding="20px 0 0" className="body_16_m gray900">
        <B_2TypeCTA mainColor={mainColor} />
      </Stack>
    </>
  );
}
