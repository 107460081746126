import React, { ReactNode } from "react";
import StepGuideFooter from "../../StepGuideFooter";
import { Box } from "@mui/material";
import { Spacing } from "../../common";
// import { SearchHeader } from "../../SearchHeader";
import { EventDomainType } from "../../../types";

export function AllRegionLayout({
  children,
  eventPath,
}: {
  children: ReactNode;
  eventPath: keyof typeof EventDomainType;
}) {
  return (
    <>
      <Box position="absolute" top={0} zIndex={-1}>
        <Box component="a" href="https://dggb-portal.funeralblog.co.kr/">
          대구 경북 포털
        </Box>
        <Box component="a" href="https://www.gyeongsang-portal.co.kr">
          부산 울산 경상 포털
        </Box>
        <Box component="a" href="https://www.metropolitan-funeral.co.kr/">
          수도권 포털
        </Box>
        <Box component="a" href="https://www.ch-funeralportal.co.kr/">
          대전 충청 포털
        </Box>
        <Box component="a" href="https://www.honam-funeralcenter.co.kr/">
          호남 포털
        </Box>
        <Box component="a" href="https://www.gangwon-portal.co.kr/">
          강원
        </Box>
        <Box component="a" href="https://heysangjo.co.kr/">
          헤이 상조
        </Box>
      </Box>
      {/* <SearchHeader eventPath={eventPath} /> */}
      {children}
      <StepGuideFooter eventPath={eventPath} />
      <Spacing gap={160} />
    </>
  );
}
